import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { VehicleId } from '@api/endpoints'
import { ClientPersonalData } from '@api/endpoints/forms'
import { Button, DialogContent, DialogTitle } from '@mui/material'
import { useLanguage } from '@hooks/useLanguage'
import { useMutationRequestBooking } from '@hooks/ReactQuery/forms/useMutationRequestBooking'
import { ModalFormProps } from '@controls/types'
import { useZodForm } from '@controls/Form'
import { ButtonClose } from '@controls/buttons'
import { CarprofModalDialog } from '@controls/dialogs/CarprofModalDialog'
import { CustomerContactsFormSchema, CustomerContactsFormValues } from '@forms/CustomerContacts/types'
import { CustomerContactsFormInputs } from '@forms/CustomerContacts'
import { ActionButtonsContainer } from '@layout/ActionButtonsContainer'
import { FlexContainer } from '@layout/FlexContainer'

type Props = ModalFormProps &
  Readonly<{
    vehicleId: VehicleId
  }>

export const ModalBooking: React.FC<Props> = ({ vehicleId, open, onClose, onSubmitSuccess }) => {
  const { t } = useTranslation()
  const { language } = useLanguage()

  const handleInvalidPhoneNumber = (): void => {
    form.setError('phoneNumber', { message: t('global.form_errors.invalid_phone_number') })
  }

  const createBookingSuccessHandler = async (): Promise<void> => {
    form.reset()
    await onSubmitSuccess()
  }

  const createBookingRequest = useMutationRequestBooking({
    onSuccess: createBookingSuccessHandler,
    onInvalidPhoneNumber: handleInvalidPhoneNumber
  })

  const form = useZodForm({ schema: CustomerContactsFormSchema })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSuccessSubmit = ({ consent, ...values }: CustomerContactsFormValues): void => {
    const data: ClientPersonalData = { language, ...values }
    createBookingRequest.mutate({ carprofCarId: vehicleId, ...data })
  }

  return (
    <CarprofModalDialog open={open} onClose={onClose}>
      <DialogTitle>
        {t('pages.vehicle.module_booking.title')}
        <ButtonClose onClick={onClose} color="secondary" />
      </DialogTitle>
      <DialogContent>
        <form onSubmit={form.handleSubmit(handleSuccessSubmit)}>
          <FlexContainer vertical>
            <CustomerContactsFormInputs
              title={t('pages.vehicle.module_booking.text')}
              control={form.control}
              disabledControls={createBookingRequest.isPending}
            />
            <ActionButtonsContainer>
              <Button type="submit" variant="contained" color="darkBlue" disabled={createBookingRequest.isPending}>
                {t('pages.vehicle.buttons.module_booking')}
              </Button>
            </ActionButtonsContainer>
          </FlexContainer>
        </form>
      </DialogContent>
    </CarprofModalDialog>
  )
}
